<template>
  <Component
    :is="isHero ? 'header' : 'SectionY'"
    :id="data.component_id"
    :class="{
      'mb-20 pt-navigation-mobile sm:mb-24 sm:pt-navigation xl:mb-28': isHero,
    }"
    :padding="data.padding?.value"
  >
    <ContainerX>
      <div
        class="mx-auto flex max-w-4xl flex-col items-center"
        :class="{ 'pt-20  sm:pt-24': isHero }"
      >
        <!-- TEXT -->
        <Component :is="isHero ? 'InViewAnimate' : 'div'">
          <Component
            :is="isHero ? 'h1' : 'h2'"
            class="mb-6 sm:mb-8"
            :class="
              isHero
                ? 'text-center text-2xl font-sans-heavy-900 sm:text-3xl'
                : 'default-component-title'
            "
          >
            <span v-html="$replaceHtmlTags(data.heading)" />
          </Component>
        </Component>
        <InViewAnimate :delay="isHero ? 200 : 0">
          <div
            class="text prose text-center text-sm sm:prose-big prose-li:text-left sm:text-md"
            v-html="data.text"
          />
        </InViewAnimate>
        <!-- BUTTON -->

        <InViewAnimate v-if="data.link" :delay="isHero ? 400 : 0">
          <AppButton :to="data.link" class="mt-7 sm:mt-9">
            {{ data.link_text }}
          </AppButton>
        </InViewAnimate>
      </div>
    </ContainerX>
  </Component>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    isHero: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
